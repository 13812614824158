// src/components/AdminLayout.js
import React from 'react';

const AdminLayout = ({ children }) => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-between items-center p-4 bg-white shadow">
        <h1 className="text-2xl font-semibold">Admin Panel</h1>
        <button 
          onClick={handleLogout}
          className="px-4 py-2 text-red-600 hover:bg-red-50 rounded"
        >
          Logout
        </button>
      </div>
      
      <div className="flex flex-col md:flex-row flex-1">
        <div className="md:w-1/4 bg-purple-50 p-4 md:min-h-screen">
          <a href="/" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md mb-4">Dashboard</a>
          <a href="/category" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md mb-4">Category</a>
          <a href="/subCategory" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md mb-4">Subcategory</a>
          <a href="/product" className="text-lg font-semibold block hover:bg-purple-100 p-2 rounded-md">Products</a>
        </div>
        <div className="md:w-3/4 p-4 flex-1">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;