import React, { useState, useEffect } from 'react';
import { careteSubCategory, getAllByCategory } from '../../Constant/Api/Api';
import { useDisclosure } from '@mantine/hooks';
import { Modal, TextInput, Button, Select, TagsInput } from '@mantine/core';
import uploadFile from '../../firebase/firebaseUploadFunction';
import { Helmet } from 'react-helmet';

const generateSlug = (name) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '');
};

function CreateSubCategory({ fetchSubCategoryData }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [metaSubCategoryName, setMetaSubCategoryName] = useState('');
    const [subCategoryDescription, setSubCategoryDescription] = useState('');
    const [metaSubCategoryDescription, setMetaSubCategoryDescription] = useState('');
    const [subCategoryImage, setSubCategoryImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [tags, setTags] = useState([]);
    const [slug, setSlug] = useState('');

    

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        setSlug(generateSlug(subCategoryName));
    }, [subCategoryName]);

    const fetchCategories = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(getAllByCategory, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            const data = await response.json();
            setCategories(data);
          }
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };

    const handleSubCategoryChange = (event) => {
        setSubCategoryName(event.target.value);
    };

    const handleMetaSubCategoryChange = (event) => {
        setMetaSubCategoryName(event.target.value);
    };

    const handleSubCategoryDescriptionChange = (event) => {
        setSubCategoryDescription(event.target.value);
    };

    const handleMetaSubCategoryDescriptionChange = (event) => {
        setMetaSubCategoryDescription(event.target.value);
    };

    const handleSubCategoryImageChange = (e) => {
        const file = e.target.files[0];
        
        
        if (!file) return;
        
        const maxSize = 100 * 1024; 
        if (file.size > maxSize) {
            
            alert("Image size should not exceed 100KB");
            
            e.target.value = '';
            return;
        }
        
        
        uploadFile(e)
            .then((fireBaseUrl) => {
                setSubCategoryImage(fireBaseUrl);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const token = localStorage.getItem('token');
            console.log("Token being sent:", token); // Debug token
            
            const formData = {
                name: subCategoryName,
                metaName: metaSubCategoryName || subCategoryName,
                description: subCategoryDescription,
                metaDescription: metaSubCategoryDescription || subCategoryDescription,
                subCategoryImage: subCategoryImage,
                category: selectedCategory,
                tags: tags,
                slug: slug,
            };
    
            console.log("API endpoint:", careteSubCategory); // Debug endpoint
            console.log("Sending data:", formData);
    
            const response = await fetch(careteSubCategory, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData),
            });
    
            console.log("Response status:", response.status);
            const data = await response.json();
            console.log("Response data:", data);
            
            if (data) {
                setMetaSubCategoryName(formData.metaName);
                setMetaSubCategoryDescription(formData.metaDescription);
                
                close();
                setSubCategoryName('');
                setMetaSubCategoryName('');
                setSubCategoryDescription('');
                setMetaSubCategoryDescription('');
                setSubCategoryImage(null);
                setTags([]);
                setSlug('');
                fetchSubCategoryData();
            }
        } catch (error) {
            console.error('Error creating subcategory:', error);
        }
    };

    return (
        <div>
            <Helmet>
                <title>{metaSubCategoryName}</title>
                <meta name="description" content={metaSubCategoryDescription} />
            </Helmet>
            <Button onClick={open}>Create SubCategory</Button>
            <Modal opened={opened} onClose={close} title="Create SubCategory">
                <form onSubmit={handleSubmit}>
                    {subCategoryImage && <img src={subCategoryImage} alt="SubCategory" className="w-full h-36 object-cover" />}
                    <TextInput
                        label="SubCategory Name"
                        placeholder="Enter subcategory name"
                        value={subCategoryName}
                        onChange={handleSubCategoryChange}
                        required
                    />
                    <TextInput
                        label="Meta SubCategory Name"
                        placeholder="Enter meta subcategory name"
                        value={metaSubCategoryName}
                        onChange={handleMetaSubCategoryChange}
                    />
                    <TextInput
                        label="Description"
                        placeholder="Enter subcategory description"
                        value={subCategoryDescription}
                        onChange={handleSubCategoryDescriptionChange}
                        required
                    />
                    <TextInput
                        label="Meta SubCategory Description"
                        placeholder="Enter meta subcategory description"
                        value={metaSubCategoryDescription}
                        onChange={handleMetaSubCategoryDescriptionChange}
                    />
                    <TextInput
                        label="Slug"
                        placeholder="Slug"
                        value={slug}
                        readOnly
                    />
                    <Select
                        label="Category"
                        data={categories.map((category) => ({ 
                            label: category.name, 
                            value: category._id 
                        }))}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    />
                    <input
                        type='file'
                        accept='image/*'
                        placeholder="Select image"
                        onChange={handleSubCategoryImageChange}
                        required
                    />
                    <TagsInput
                        label="Press Enter to submit a tag"
                        placeholder="Enter tag"
                        value={tags}
                        onChange={setTags}
                    />
                    <Button type="submit">Create</Button>
                </form>
            </Modal>
        </div>
    );
}

export default CreateSubCategory;