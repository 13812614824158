import React, { useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';
import ProductFaqForm from './ProductFaqForm';
// import ProductFaqForm from './ProductFaqForm';

function ProductFAQModal({ product, onClose }) {
    const [opened, { open, close }] = useDisclosure(true);

    console.log("from ProductFAQModal----", product);

    useEffect(() => {
    }, []);

    return (
        <div>
            <Modal opened={opened} onClose={onClose} title="Manage Product FAQs">
                <ProductFaqForm productId={product._id} />
            </Modal>
        </div>
    );
}

export default ProductFAQModal;