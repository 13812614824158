import React, { useState, useEffect } from 'react';
import { deleteProductByID } from '../../Constant/Api/Api'; // Adjust the import as needed
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

function DeleteProduct({ product, onClose }) {
    const [opened, { open, close }] = useDisclosure(true);
    const [productId, setProductId] = useState('');

    useEffect(() => {
        if (product) {
            setProductId(product._id || '');
        }
    }, [product]);

    const handleDelete = async () => {
        try {
            const token=localStorage.getItem('token')

            const response = await fetch(`${deleteProductByID}${productId}`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log('Product deleted successfully.');
                onClose(); // Close the modal on successful deletion
            } else {
                console.error('Failed to delete product.');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    return (
        <div>
            <Modal opened={opened} onClose={onClose} title="Delete Product">
                <h1 className='bg-slate-100 font-semibold'>Are you sure you want to delete this product?</h1>
                <p>Product ID: {productId}</p>
                <p>Product Name: {product.name}</p>
                {/* Add other relevant information about the product */}
                <div className="mt-4 flex justify-end space-x-4">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="red" onClick={handleDelete}>Delete</Button>
                </div>
            </Modal>
        </div>
    );
}

export default DeleteProduct;
