import React, { useState, useEffect } from "react";
import {
  createProduct,
  getAllByCategory,
  getAllSubCategory,
} from "../../Constant/Api/Api";
import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  TextInput,
  Button,
  Select,
  TagsInput,
  Progress,
  NumberInput,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import uploadFile from "../../firebase/firebaseUploadFunction";
import "@mantine/tiptap/styles.css";
import "@mantine/core/styles.css";
import { MdDelete } from "react-icons/md";

const generateSlug = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
};

const tagOptions = [
  // RG Cables
  { value: "rg59", label: "RG 59" },
  { value: "rg6", label: "RG 6" },
  { value: "rg11", label: "RG11" },
  { value: "rg11_ccs", label: "RG11 CCS" },
  { value: "rg6_ccs", label: "RG6 CCS" },

  // Conductor Sizes
  { value: "0.5", label: "0.5" },
  { value: "0.75", label: "0.75" },
  { value: "1", label: "1" },
  { value: "1.5", label: "1.5" },
  { value: "2.5", label: "2.5" },
  { value: "4", label: "4" },
  { value: "6", label: "6" },
  { value: "10", label: "10" },
  { value: "16", label: "16" },
  { value: "25", label: "25" },
  { value: "35", label: "35" },
  { value: "50", label: "50" },
  { value: "70", label: "70" },
  { value: "95", label: "95" },
  { value: "120", label: "120" },
  { value: "150", label: "150" },
  { value: "185", label: "185" },
  { value: "240", label: "240" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },

  // Ethernet Categories
  { value: "cat1", label: "Category 1 1 Mbps 0.4 MHz" },
  { value: "cat2", label: "Category 2 4 Mbps 4 MHz" },
  { value: "cat3", label: "Category 3 10 Mbps 16 MHz 100m" },
  { value: "cat4", label: "Category 4 16 Mbps 20 MHz 100m" },
  { value: "cat5", label: "Category 5 100 Mbps 100 MHz 100m" },
  { value: "cat5e", label: "Category 5e 1 Gbps 100 MHz 100m" },
  { value: "cat6", label: "Category 6 1 Gbps 250 MHz 100m" },
  { value: "cat6a", label: "Category 6a 10 Gbps 500 MHz 100m" },
  { value: "cat7", label: "Category 7 10 Gbps 600 MHz 100m" },
  { value: "cat7a", label: "Category 7a 10 Gbps 1000 MHz 100m" },
  { value: "cat8", label: "Category 8 25/40 Gbps 2000 MHz 30m" },
];

const keyOptions = [
  // General Properties
  { value: "colour", label: "Colour" },
  { value: "conductor", label: "Conductor" },
  { value: "durability_features", label: "Durability Features" },
  { value: "connector_type", label: "Connector Type" },
  { value: "standards_compliance", label: "Standards Compliance" },
  { value: "temperature_rating", label: "Temperature Rating" },

  // Conductor Properties
  { value: "conductor_type", label: "Conductor Type" },
  { value: "conductor_material", label: "Conductor Material" },

  // Insulation Properties
  { value: "insulation_material", label: "Insulation Material" },

  // Physical Properties
  { value: "outer_diameter", label: "Outer Diameter (OD)" },

  // Performance Properties
  { value: "performance", label: "Performance" },
  { value: "bandwidth", label: "Bandwidth" },
  { value: "shielding", label: "Shielding" },
  { value: "category", label: "Category" },
  { value: "voltage_rating", label: "Voltage Rating" },

  // Environmental Properties
  { value: "temperature_range", label: "Temperature Range" },

  // Sheath and Color Properties
  { value: "sheath_color", label: "Sheath Color" },

  // Standards and Compliance
  { value: "applicable_standards", label: "Applicable Standards" },
];

function CreateProduct({ fetchProductData }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
  const [sellingPrice, setSellingPrice] = useState("");
  const [mrp, setMrp] = useState("");
  const [specifications, setSpecifications] = useState([
    { tag: "", key: "", value: [] },
  ]);
  const [slug, setSlug] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [customTag, setCustomTag] = useState("");
  const [customKey, setCustomKey] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [customValue, setCustomValue] = useState([]);
  const [metaProductName, setMetaProductName] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [imageError, setImageError] = useState("");

  const handleDropdownAdd = () => {
    if (selectedTag && selectedKey && selectedValue.length > 0) {
      const newSpec = {
        tag: selectedTag,
        key: selectedKey,
        value: selectedValue,
      };
      setSpecifications([...specifications, newSpec]);

      // Clear inputs
      setSelectedTag("");
      setSelectedKey("");
      setSelectedValue([]);
    }
  };
  const clearAllStates = () => {
    setProductName("");
    setMetaProductName("");
    setMetaDescription("");
    editor?.commands.setContent("");
    metaEditor?.commands.setContent("");
    setProductImages([]);
    setSelectedCategory("");
    setSelectedSubCategory("");
    setTags([]);
    setSellingPrice("");
    setMrp("");
    setSpecifications([]);
    setSlug("");
    setSelectedTag("");
    setSelectedKey("");
    setSelectedValue([]);
    setCustomTag("");
    setCustomKey("");
    setCustomValue([]);
    setImageError("");
    
  };

  const handleCustomAdd = () => {
    if ((customTag || customKey) && customValue.length > 0) {
      const newSpec = {
        tag: customTag,
        key: customKey,
        value: customValue,
      };
      setSpecifications([...specifications, newSpec]);
      // Clear all custom inputs
      setCustomTag("");
      setCustomKey("");
      setCustomValue([]);
    }
  };


  const removeSpecification = (index) => {
    setSpecifications(
      specifications.filter((_, specIndex) => specIndex !== index)
    );
  };
  const metaEditor = useEditor({
    extensions: [
      StarterKit.configure({
        hardBreak: true,
        paragraph: {
          HTMLAttributes: {
            class: 'whitespace-pre-wrap',
          },
        }
      }),
      Link,
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const rawContent = editor.getHTML();
      const withLineBreaks = rawContent.replace(/<p>/g, '').replace(/<\/p>/g, '<br/>');
      const preservedContent = withLineBreaks
        .replace(/ /g, '&nbsp;')
        .replace(/\n/g, '<br/>')
        .replace(/\r/g, '<br/>');
      setMetaDescription(preservedContent);
    },
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none whitespace-pre-wrap',
      },
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        hardBreak: true,
        paragraph: {
          HTMLAttributes: {
            class: "whitespace-pre-wrap",
          },
        },
      }),
      Link,
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const rawContent = editor.getHTML();
      // First convert paragraphs to line breaks
      const withLineBreaks = rawContent
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "<br/>");
      // Then preserve spaces and handle remaining breaks
      const preservedContent = withLineBreaks
        .replace(/ /g, "&nbsp;")
        .replace(/\n/g, "<br/>")
        .replace(/\r/g, "<br/>");
      setProductDescription(preservedContent);
    },
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none whitespace-pre-wrap",
      },
    },
  });

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, []);

  useEffect(() => {
    setSlug(generateSlug(productName));
  }, [productName]);

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(getAllByCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategories = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(getAllSubCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSubCategories(data);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleProductImagesChange = (e) => {
    setImageUploadingLoader(true);
    
    const files = Array.from(e.target.files);
    const maxSize = 100 * 1024; // 100KB in bytes
    
    // Check if any file exceeds the size limit
    const oversizedFiles = files.filter(file => file.size > maxSize);
    
    if (oversizedFiles.length > 0) {
      setImageUploadingLoader(false);
      alert(`${oversizedFiles.length} image(s) exceed the 100KB size limit. Please resize your images.`);
      // Clear the file input
      e.target.value = '';
      return;
    }
    
    // If all files are within size limit, proceed with upload
    const uploadPromises = files.map(file => {
      const fileEvent = { target: { files: [file] } };
      return uploadFile(fileEvent);
    });
    
    Promise.all(uploadPromises)
      .then((fireBaseUrls) => {
        setImageUploadingLoader(false);
        setProductImages([...productImages, ...fireBaseUrls]);
      })
      .catch((error) => {
        setImageUploadingLoader(false);
        console.error("Error uploading files:", error);
      });
  };
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleSubCategoryChange = (value) => {
    setSelectedSubCategory(value);
  };

  const handleSpecificationChange = (index, event) => {
    const newSpecifications = specifications.map((spec, specIndex) => {
      if (index === specIndex) {
        return {
          ...spec,
          [event.target.name]: event.target.value,
        };
      }
      return spec;
    });
    setSpecifications(newSpecifications);
  };

  const handleSpecificationTagsChange = (index, value) => {
    const newSpecifications = specifications.map((spec, specIndex) => {
      if (index === specIndex) {
        return { ...spec, value: Array.isArray(value) ? value : [value] };
      }
      return spec;
    });
    setSpecifications(newSpecifications);
  };

  const addSpecification = () => {
    setSpecifications([...specifications, { tag: "", key: "", value: [] }]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (productImages.length < 5) {
      setImageError("At least 5 product images are required");
      return;
    } else {
      setImageError("");
    }

    try {
      const token = localStorage.getItem("token");
      const filteredSpecifications = specifications.filter(
        spec => spec.tag !== "" && spec.key !== "" && spec.value.length > 0
      );
      const formData = {
        name: productName,
        metaName: metaProductName || productName, // Use productName as fallback
        description: productDescription,
        metaDescription: metaDescription || productDescription,
        images: productImages,
        category: selectedCategory,
        subCategory: selectedSubCategory,
        tags,
        sellingPrice,
        mrp,
        specifications: filteredSpecifications,
        slug,
      };

      console.log("sending data--->", formData);
      const response = await fetch(createProduct, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data) {
        setProductName("");
        setMetaProductName("");
        setMetaDescription("");
        editor.commands.setContent("");
        setProductImages([]);
        setSelectedCategory("");
        setSelectedSubCategory("");
        setTags([]);
        setSellingPrice("");
        setMrp("");
        setSpecifications([{ tag: "", key: "", value: [] }]);
        fetchProductData();
        close();
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  return (
    <div>
      <Button onClick={open}>Create Product</Button>
      <Modal
        opened={opened}
        onClose={() => {
          clearAllStates();
          close();
        }}
        title="Create Product"
        size="xl"
      >
        <form onSubmit={handleSubmit}>
          {imageUploadingLoader ? (
            <>
              <span className="bg-red-50">
                Please wait while the image is uploading...{" "}
              </span>
              <Progress radius="xl" value={100} striped animated />
            </>
          ) : (
            <>
              {productImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Product Image ${index}`}
                  className="w-full h-36 object-cover"
                />
              ))}
              {imageError && <div className="text-red-500 mb-2">{imageError}</div>}
              <TextInput
                label="Product Name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
              <TextInput
                label="Meta Title (SEO)"
                placeholder="Enter meta title for SEO"
                value={metaProductName}
                onChange={(e) => setMetaProductName(e.target.value)}
              />
              <div className="mb-4">
                <h3>Description</h3>
                <RichTextEditor
                  editor={editor}
                  styles={{ root: { minHeight: 300 } }}
                >
                  <RichTextEditor.Toolbar sticky stickyOffset={0}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ColorPicker
                      colors={[
                        "#25262b",
                        "#868e96",
                        "#fa5252",
                        "#e64980",
                        "#be4bdb",
                        "#7950f2",
                        "#4c6ef5",
                        "#228be6",
                        "#15aabf",
                        "#12b886",
                        "#40c057",
                        "#82c91e",
                        "#fab005",
                        "#fd7e14",
                      ]}
                    />
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content />
                </RichTextEditor>
              </div>
              <div className="mb-4">
                <h3>Meta Description</h3>
                <RichTextEditor
                  editor={metaEditor}
                  styles={{ root: { minHeight: 300 } }}
                >
                  <RichTextEditor.Toolbar sticky stickyOffset={0}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ColorPicker
                      colors={[
                        "#25262b",
                        "#868e96",
                        "#fa5252",
                        "#e64980",
                        "#be4bdb",
                        "#7950f2",
                        "#4c6ef5",
                        "#228be6",
                        "#15aabf",
                        "#12b886",
                        "#40c057",
                        "#82c91e",
                        "#fab005",
                        "#fd7e14",
                      ]}
                    />
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content />
                </RichTextEditor>
              </div>
              <TextInput label="Slug" value={slug} readOnly />
              <Select
                label="Category"
                data={categories.map((category) => ({
                  label: category.name,
                  value: category._id,
                }))}
                value={selectedCategory}
                onChange={(value) => handleCategoryChange(value)}
                searchable
              />
              <Select
                label="Subcategory"
                data={subCategories.map((subcategory) => ({
                  label: subcategory.name,
                  value: subcategory._id,
                }))}
                value={selectedSubCategory}
                onChange={(value) => handleSubCategoryChange(value)}
                searchable
              />
              <TagsInput
                label="Press Enter to submit a tag"
                placeholder="Enter tag"
                value={tags}
                onChange={setTags}
              />
              <NumberInput
                label="Selling Price"
                value={sellingPrice}
                onChange={setSellingPrice}
              />
              <NumberInput label="MRP" value={mrp} onChange={setMrp} />
              <div>
                <label>Specifications</label>

                <div className="flex gap-2.5 items-center mb-2.5">
                  <select
                    value={selectedTag}
                    onChange={(e) => setSelectedTag(e.target.value)}
                    className="w-[10vw] h-[36px] rounded border border-gray-300 px-2"
                  >
                    <option value="">Select Tag</option>
                    {tagOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <select
                    value={selectedKey}
                    onChange={(e) => setSelectedKey(e.target.value)}
                    className="w-[10vw] h-[36px] rounded border border-gray-300 px-2"
                  >
                    <option value="">Select Key</option>
                    {keyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <TagsInput
                    placeholder="Enter value"
                    value={selectedValue}
                    onChange={setSelectedValue}
                    className="flex-1"
                  />
                  <Button onClick={handleDropdownAdd}>Add</Button>
                </div>

                <div className="flex gap-2.5 items-center mb-2.5">
                  <TextInput
                    placeholder="Enter custom tag"
                    value={customTag}
                    onChange={(e) => setCustomTag(e.target.value)}
                    className="w-[10vw]"
                  />
                  <TextInput
                    placeholder="Enter custom key"
                    value={customKey}
                    onChange={(e) => setCustomKey(e.target.value)}
                    className="w-[10vw]"
                  />
                  <TagsInput
                    placeholder="Enter value"
                    value={customValue}
                    onChange={setCustomValue}
                    className="flex-1"
                  />
                  <Button onClick={handleCustomAdd}>Add</Button>
                </div>

                {specifications?.map((spec, index) => (
                  <div key={index} className="flex gap-2.5 items-center mb-2.5">
                    <TextInput
                      placeholder="Tag"
                      name="tag"
                      value={spec.tag}
                      onChange={(event) =>
                        handleSpecificationChange(index, event)
                      }
                      className="w-[10vw]"
                    />
                    <TextInput
                      placeholder="Key"
                      name="key"
                      value={spec.key}
                      onChange={(event) =>
                        handleSpecificationChange(index, event)
                      }
                      className="w-[10vw]"
                    />
                    <TagsInput
                      placeholder="Enter value"
                      value={spec.value}
                      onChange={(value) =>
                        handleSpecificationTagsChange(index, value)
                      }
                      className="flex-1"
                    />
                  </div>
                ))}
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleProductImagesChange(e)}
                multiple
              />
              <Button type="submit">Create</Button>
            </>
          )}
        </form>
      </Modal>
    </div>
  );
}

export default CreateProduct;
