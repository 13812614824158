import React, { useState, useEffect } from "react";
import {
  updateProductByID,
  getAllByCategory,
  getAllSubCategory,
} from "../../Constant/Api/Api";
import {
  Modal,
  TextInput,
  Button,
  Select,
  TagsInput,
  NumberInput,
} from "@mantine/core";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import uploadFile from "../../firebase/firebaseUploadFunction";
import { MdDelete } from "react-icons/md";

const generateSlug = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
};

const tagOptions = [
  // RG Cables
  { value: "RG59", label: "RG 59" },
  { value: "RG6", label: "RG 6" },
  { value: "RG11", label: "RG11" },
  { value: "RG11_CCS", label: "RG11 CCS" },
  { value: "RG6_CCS", label: "RG6 CCS" },

  // Conductor Sizes
  { value: "0.5", label: "0.5" },
  { value: "0.75", label: "0.75" },
  { value: "1", label: "1" },
  { value: "1.5", label: "1.5" },
  { value: "2.5", label: "2.5" },
  { value: "4", label: "4" },
  { value: "6", label: "6" },
  { value: "10", label: "10" },
  { value: "16", label: "16" },
  { value: "25", label: "25" },
  { value: "35", label: "35" },
  { value: "50", label: "50" },
  { value: "70", label: "70" },
  { value: "95", label: "95" },
  { value: "120", label: "120" },
  { value: "150", label: "150" },
  { value: "185", label: "185" },
  { value: "240", label: "240" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },

  // Ethernet Categories
  { value: "CAT1", label: "Category 1 1 Mbps 0.4 MHz" },
  { value: "CAT2", label: "Category 2 4 Mbps 4 MHz" },
  { value: "CAT3", label: "Category 3 10 Mbps 16 MHz 100m" },
  { value: "CAT4", label: "Category 4 16 Mbps 20 MHz 100m" },
  { value: "CAT5", label: "Category 5 100 Mbps 100 MHz 100m" },
  { value: "CAT5E", label: "Category 5e 1 Gbps 100 MHz 100m" },
  { value: "CAT6", label: "Category 6 1 Gbps 250 MHz 100m" },
  { value: "CAT6A", label: "Category 6a 10 Gbps 500 MHz 100m" },
  { value: "CAT7", label: "Category 7 10 Gbps 600 MHz 100m" },
  { value: "CAT7A", label: "Category 7a 10 Gbps 1000 MHz 100m" },
  { value: "CAT8", label: "Category 8 25/40 Gbps 2000 MHz 30m" },
];

const keyOptions = [
  // General Properties
  { value: "Colour", label: "Colour" },
  { value: "Conductor", label: "Conductor" },
  { value: "Durability_features", label: "Durability Features" },
  { value: "Connector_type", label: "Connector Type" },
  { value: "Standards_compliance", label: "Standards Compliance" },
  { value: "Temperature_rating", label: "Temperature Rating" },

  // Conductor Properties
  { value: "Conductor_type", label: "Conductor Type" },
  { value: "Conductor_material", label: "Conductor Material" },

  // Insulation Properties
  { value: "Insulation_material", label: "Insulation Material" },

  // Physical Properties
  { value: "Outer_diameter", label: "Outer Diameter (OD)" },

  // Performance Properties
  { value: "Performance", label: "Performance" },
  { value: "Bandwidth", label: "Bandwidth" },
  { value: "Shielding", label: "Shielding" },
  { value: "Category", label: "Category" },
  { value: "Voltage_rating", label: "Voltage Rating" },

  // Environmental Properties
  { value: "Temperature_range", label: "Temperature Range" },

  // Sheath and Color Properties
  { value: "Sheath_color", label: "Sheath Color" },

  // Standards and Compliance
  { value: "Applicable_standards", label: "Applicable Standards" },
];

export default function EditProduct({ product, onClose }) {
  const [productName, setProductName] = useState(product.name || "");
  const [productDescription, setProductDescription] = useState(
    product.description || ""
  );
  const [productImages, setProductImages] = useState(product.images || []);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    product.category?._id || ""
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    product.subCategory?._id || ""
  );
  const [tags, setTags] = useState(product.tags || []);
  const [sellingPrice, setSellingPrice] = useState(product.sellingPrice || "");
  const [mrp, setMrp] = useState(product.mrp || "");
  const [specifications, setSpecifications] = useState(
    product.specifications || [{ tag: "", key: "", value: [] }]
  );
  const [slug, setSlug] = useState(product.slug || "");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [customTag, setCustomTag] = useState("");
  const [customKey, setCustomKey] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [customValue, setCustomValue] = useState([]);
  const [metaProductName, setMetaProductName] = useState(
    product.metaName || ""
  );
  const [metaDescription, setMetaDescription] = useState(
    product.metaDescription || ""
  );
  const [dropdownOptions, setDropdownOptions] = useState({
    tag: tagOptions,
    key: keyOptions,
  });

  const handleDropdownAdd = () => {
    if (selectedTag && selectedKey && selectedValue.length > 0) {
      const newSpec = {
        tag: selectedTag,
        key: selectedKey,
        value: selectedValue,
      };
      setSpecifications([...specifications, newSpec]);

      // Clear inputs
      setSelectedTag("");
      setSelectedKey("");
      setSelectedValue([]);
    }
  };

  const handleCustomAdd = () => {
    if ((customTag || customKey) && customValue.length > 0) {
      const newSpec = {
        tag: customTag,
        key: customKey,
        value: customValue,
      };
      setSpecifications([...specifications, newSpec]);
      // Clear all custom inputs
      setCustomTag("");
      setCustomKey("");
      setCustomValue([]);
    }
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: product.description || "",
    onUpdate: ({ editor }) => {
      setProductDescription(editor.getHTML());
    },
  });

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(getAllByCategory);
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategories = async () => {
    try {
      const response = await fetch(getAllSubCategory);
      if (response.ok) {
        const data = await response.json();
        setSubCategories(data);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };


  
  const metaEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: product.metaDescription || "",
    onUpdate: ({ editor }) => {
      setMetaDescription(editor.getHTML());
    },
  });

  const handleProductImagesChange = (e) => {
    uploadFile(e)
      .then((fireBaseUrl) => {
        setProductImages([...productImages, fireBaseUrl]);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleSpecificationChange = (index, event) => {
    const newSpecifications = specifications.map((spec, specIndex) => {
      if (index === specIndex) {
        return { ...spec, [event.target.name]: event.target.value };
      }
      return spec;
    });
    setSpecifications(newSpecifications);
  };

  const handleSpecificationTagsChange = (index, value) => {
    const newSpecifications = specifications.map((spec, specIndex) => {
      if (index === specIndex) {
        return { ...spec, value: Array.isArray(value) ? value : [value] };
      }
      return spec;
    });
    setSpecifications(newSpecifications);
  };

  const addSpecification = () => {
    setSpecifications([...specifications, { tag: "", key: "", value: [] }]);
  };

  const removeSpecification = (index) => {
    setSpecifications(
      specifications.filter((_, specIndex) => specIndex !== index)
    );
  };

  const handleDeleteImage = (index) => {
    setProductImages(productImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const formData = {
        name: productName,
        metaName: metaProductName || productName,
        description: productDescription,
        metaDescription: metaDescription || productDescription,
        images: productImages,
        category: selectedCategory,
        subCategory: selectedSubCategory,
        tags,
        sellingPrice,
        mrp,
        specifications,
        slug: slug || generateSlug(productName),
      };

      const response = await fetch(`${updateProductByID}${product._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data) {
        onClose();
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  return (
    <Modal opened={true} onClose={onClose} title="Edit Product" size="xl">
      <div className="flex flex-row">
        {productImages?.map((image, index) => (
          <div key={index} className="">
            <img
              src={image}
              alt={`Product Image ${index}`}
              className="object-scale-down w-32 h-32"
            />
            <MdDelete
              onClick={() => handleDeleteImage(index)}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            />
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Product Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          required
        />
        <TextInput
          label="Meta Title (SEO)"
          value={metaProductName}
          onChange={(e) => setMetaProductName(e.target.value)}
        />

        <div className="mb-4">
          <h3>Description</h3>
          <RichTextEditor editor={editor} styles={{ root: { minHeight: 300 } }}>
            <RichTextEditor.Toolbar sticky stickyOffset={0}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ColorPicker
                colors={[
                  "#25262b",
                  "#868e96",
                  "#fa5252",
                  "#e64980",
                  "#be4bdb",
                  "#7950f2",
                  "#4c6ef5",
                  "#228be6",
                  "#15aabf",
                  "#12b886",
                  "#40c057",
                  "#82c91e",
                  "#fab005",
                  "#fd7e14",
                ]}
              />
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
        </div>
        <div className="mb-4">
          <h3>Meta Description</h3>
          <RichTextEditor
            editor={metaEditor}
            styles={{ root: { minHeight: 300 } }}
          >
            <RichTextEditor.Toolbar sticky stickyOffset={0}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ColorPicker
                colors={[
                  "#25262b",
                  "#868e96",
                  "#fa5252",
                  "#e64980",
                  "#be4bdb",
                  "#7950f2",
                  "#4c6ef5",
                  "#228be6",
                  "#15aabf",
                  "#12b886",
                  "#40c057",
                  "#82c91e",
                  "#fab005",
                  "#fd7e14",
                ]}
              />
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
        </div>

        <TextInput
          label="Slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />

        <Select
          label="Category"
          data={categories.map((category) => ({
            label: category.name,
            value: category._id,
          }))}
          value={selectedCategory}
          onChange={(value) => setSelectedCategory(value)}
        />

        <Select
          label="Subcategory"
          data={subCategories.map((subcategory) => ({
            label: subcategory.name,
            value: subcategory._id,
          }))}
          value={selectedSubCategory}
          onChange={(value) => setSelectedSubCategory(value)}
        />

        <TagsInput
          label="Press Enter to submit a tag"
          placeholder="Enter tag"
          value={tags}
          onChange={setTags}
        />

        <NumberInput
          label="Selling Price"
          value={sellingPrice}
          onChange={setSellingPrice}
        />

        <NumberInput label="MRP" value={mrp} onChange={setMrp} />

        <div>
          <label>Specifications</label>

          <div className="flex gap-2.5 items-center mb-2.5">
            <select
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
              className="w-[10vw] h-[36px] rounded border border-gray-300 px-2"
            >
              <option value="">Select SKU</option>
              {tagOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <select
              value={selectedKey}
              onChange={(e) => setSelectedKey(e.target.value)}
              className="w-[10vw] h-[36px] rounded border border-gray-300 px-2"
            >
              <option value="">Select Key</option>
              {keyOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <TagsInput
              placeholder="Enter value"
              value={selectedValue}
              onChange={setSelectedValue}
              className="flex-1"
            />
            <Button onClick={handleDropdownAdd}>Add</Button>
          </div>

          <div className="flex gap-2.5 items-center mb-2.5">
            {/* Custom section */}
            <TextInput
              placeholder="Enter custom tag"
              value={customTag}
              onChange={(e) => setCustomTag(e.target.value)}
              className="w-[10vw]"
            />
            <TextInput
              placeholder="Enter custom key"
              value={customKey}
              onChange={(e) => setCustomKey(e.target.value)}
              className="w-[10vw]"
            />
            <TagsInput
              placeholder="Enter value"
              value={customValue}
              onChange={setCustomValue}
              className="flex-1"
            />
            <Button onClick={handleCustomAdd}>Add</Button>
          </div>

          {/* Existing specifications list */}
          {specifications?.map((spec, index) => (
            <div key={index} className="flex gap-2.5 items-center mb-2.5">
              <TextInput
                placeholder="Tag"
                name="tag"
                value={spec.tag}
                onChange={(event) => handleSpecificationChange(index, event)}
                className="w-[10vw]"
              />
              <TextInput
                placeholder="Key"
                name="key"
                value={spec.key}
                onChange={(event) => handleSpecificationChange(index, event)}
                className="w-[10vw]"
              />
              <TagsInput
                placeholder="Enter value"
                value={spec.value}
                onChange={(value) =>
                  handleSpecificationTagsChange(index, value)
                }
                className="flex-1"
              />
              <MdDelete
                className="text-red-900 cursor-pointer"
                onClick={() => removeSpecification(index)}
              />
            </div>
          ))}
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleProductImagesChange(e)}
          multiple
        />

        <Button type="submit">Update</Button>
      </form>
    </Modal>
  );
}
