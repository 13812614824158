import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminLayout from '../components/AdminLayout';
import Home from '../components/Home';
import Category from '../components/category/ShowCategories';
import ShowSubCategories from '../components/subCategory/ShowSubCategories';
import ShowProducts from '../components/product/ShowProducts';
import LoginPage from '../components/auth/LoginPage';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  
  if (!token) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default function CustomRoutes() {
  return (
    <Routes>
      {/* Public Route - Login */}
      <Route path="/login" element={<LoginPage />} />

      {/* Protected Routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <AdminLayout>
              <Home />
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/category"
        element={
          <ProtectedRoute>
            <AdminLayout>
              <Category />
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/subCategory"
        element={
          <ProtectedRoute>
            <AdminLayout>
              <ShowSubCategories />
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/product"
        element={
          <ProtectedRoute>
            <AdminLayout>
              <ShowProducts />
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      {/* Catch all route - redirect to home if authenticated, login if not */}
      <Route
        path="*"
        element={
          localStorage.getItem('token') ? 
            <Navigate to="/" replace /> : 
            <Navigate to="/login" replace />
        }
      />
    </Routes>
  );
}