import React, { useState, useEffect } from "react";
import { TextInput, Textarea, Button, Loader } from "@mantine/core";
import axios from "axios";
import {
  getProductByID,
  addProductFaq,
  updateProductFaq,
  deleteProductFaq,
} from "../../../Constant/Api/Api";
import { debounce } from "lodash";
import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

const ProductFaqForm = ({ productId }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingFaq, setEditingFaq] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedAnswer, setEditedAnswer] = useState("");

  // Configure axios with authentication headers
  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };
  };

  const fetchProduct = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `${getProductByID}${productId}`, 
        getAuthHeaders()
      );
      if (response.data && Array.isArray(response.data.faqs)) {
        setFaqs(response.data.faqs);
      } else {
        setFaqs([]);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      if (error.response && error.response.status === 401) {
        setError("Authentication required. Please log in again.");
        // Optionally redirect to login page
        // window.location.href = '/login';
      } else {
        setError("Failed to load FAQs. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
  }, [productId]);

  const addFaq = async () => {
    if (!question.trim() || !answer.trim()) {
      return;
    }
    try {
      const response = await axios.post(
        addProductFaq, 
        {
          id: productId,
          question,
          answer,
        },
        getAuthHeaders()
      );

      if (response.data && Array.isArray(response.data.faqs)) {
        setFaqs(response.data.faqs);
      }

      setQuestion("");
      setAnswer("");
      editor.commands.setContent(""); // Clear the editor content
    } catch (error) {
      console.error("Error adding FAQ:", error);
      if (error.response && error.response.status === 401) {
        setError("Authentication required. Please log in again.");
      } else {
        setError("Failed to add FAQ. Please try again.");
      }
    }
  };

  const startEditing = (faq) => {
    setEditingFaq(faq._id);
    setEditedQuestion(faq.question);
    setEditedAnswer(faq.answer);
    editor.commands.setContent(faq.answer); // Set the editor content to the FAQ's answer
  };

  const cancelEditing = () => {
    setEditingFaq(null);
    setEditedQuestion("");
    setEditedAnswer("");
    editor.commands.setContent(""); // Clear the editor content
  };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        hardBreak: true,
        paragraph: {
          HTMLAttributes: {
            class: 'whitespace-pre-wrap',
          },
        }
      }),
      Link,
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const rawContent = editor.getHTML();
      const withLineBreaks = rawContent.replace(/<p>/g, '').replace(/<\/p>/g, '<br/>');
      const preservedContent = withLineBreaks
        .replace(/ /g, '&nbsp;')
        .replace(/\n/g, '<br/>')
        .replace(/\r/g, '<br/>');
      setAnswer(preservedContent);
    }
  });

  const updateFaq = async (faqId, updatedQuestion, updatedAnswer) => {
    try {
      const response = await axios.post(
        updateProductFaq, 
        {
          id: productId,
          faqId,
          question: updatedQuestion,
          answer: updatedAnswer,
        },
        getAuthHeaders()
      );

      if (response.data && Array.isArray(response.data.faqs)) {
        setFaqs(response.data.faqs);
        setEditingFaq(null); // Exit edit mode after successful update
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
      if (error.response && error.response.status === 401) {
        setError("Authentication required. Please log in again.");
      } else {
        setError("Failed to update FAQ. Please try again.");
      }
    }
  };

  const deleteFaq = async (faqId) => {
    try {
      const response = await axios.post(
        deleteProductFaq, 
        {
          id: productId,
          faqId,
        },
        getAuthHeaders()
      );

      if (response.data && Array.isArray(response.data.faqs)) {
        setFaqs(response.data.faqs);
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      if (error.response && error.response.status === 401) {
        setError("Authentication required. Please log in again.");
      } else {
        setError("Failed to delete FAQ. Please try again.");
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <Loader size="md" />
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">{error}</div>
      )}

      <div className="mb-6 bg-gray-50 p-4 rounded">
        <h3 className="text-lg font-medium mb-3">Add New FAQ</h3>
        <TextInput
          label="Question"
          value={question}
          onChange={(e) => setQuestion(e.currentTarget.value)}
          placeholder="Enter your question"
          className="mb-3"
        />
        <div className="mb-4">
          <h3>Answer</h3>
          <RichTextEditor
            editor={editor}
            styles={{ root: { minHeight: 300 } }}
          >
            <RichTextEditor.Toolbar sticky stickyOffset={0}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ColorPicker
                colors={[
                  "#25262b",
                  "#868e96",
                  "#fa5252",
                  "#e64980",
                  "#be4bdb",
                  "#7950f2",
                  "#4c6ef5",
                  "#228be6",
                  "#15aabf",
                  "#12b886",
                  "#40c057",
                  "#82c91e",
                  "#fab005",
                  "#fd7e14",
                ]}
              />
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
        </div>

        <Button
          className="mt-2"
          onClick={addFaq}
          disabled={!question.trim() || !answer.trim()}
        >
          Add FAQ
        </Button>
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-medium mb-4">Existing FAQs</h3>
        {faqs.length === 0 ? (
          <div className="text-gray-500 text-center py-4">
            No FAQs available. Add your first FAQ above.
          </div>
        ) : (
          faqs.map((faq) => (
            <div
              key={faq._id}
              className="mb-4 p-4 border rounded shadow-sm hover:shadow-md transition-shadow"
            >
              {editingFaq === faq._id ? (
                <>
                  <TextInput
                    label="Question"
                    value={editedQuestion}
                    onChange={(e) => setEditedQuestion(e.currentTarget.value)}
                    className="mb-3"
                  />
                  <RichTextEditor
                    editor={editor}
                    styles={{ root: { minHeight: 300 } }}
                  >
                    <RichTextEditor.Toolbar sticky stickyOffset={0}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ColorPicker
                        colors={[
                          "#25262b",
                          "#868e96",
                          "#fa5252",
                          "#e64980",
                          "#be4bdb",
                          "#7950f2",
                          "#4c6ef5",
                          "#228be6",
                          "#15aabf",
                          "#12b886",
                          "#40c057",
                          "#82c91e",
                          "#fab005",
                          "#fd7e14",
                        ]}
                      />
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content />
                  </RichTextEditor>
                  <div className="flex gap-2">
                    <Button
                      color="blue"
                      onClick={() => updateFaq(faq._id, editedQuestion, editor.getHTML())}
                      className="mt-2"
                    >
                      Save Changes
                    </Button>
                    <Button
                      color="gray"
                      onClick={cancelEditing}
                      className="mt-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <TextInput
                    label="Question"
                    value={faq.question}
                    readOnly
                    className="mb-3"
                  />
                  <div
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                  <div className="flex gap-2">
                    <Button
                      color="blue"
                      onClick={() => startEditing(faq)}
                      className="mt-2"
                    >
                      Edit FAQ
                    </Button>
                    <Button
                      color="red"
                      onClick={() => deleteFaq(faq._id)}
                      className="mt-2"
                    >
                      Delete FAQ
                    </Button>
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProductFaqForm;