// import React, { useState, useEffect } from 'react';
// import { Button, Group, HoverCard, Select, Text } from '@mantine/core'; // Import Mantine's Select component
// import { getAllProducts, getAllByCategory } from '../../Constant/Api/Api'; // Adjust the import as needed
// import { FaRegEdit } from 'react-icons/fa';
// import { MdDelete } from 'react-icons/md';
// import EditProduct from './EditProduct'; // Create this component
// import DeleteProduct from './DeleteProduct'; // Create this component
// import CreateProduct from './CreateProduct';
// import BrnadImages from './BrandImages/BrandImages';
// import { CiImageOn } from 'react-icons/ci';

// function ShowProducts() {
//     const [productData, setProductData] = useState([]);
//     const [categoryData, setCategoryData] = useState([]);
//     const [selectedProduct, setSelectedProduct] = useState(null);
//     const [editModalOpen, setEditModalOpen] = useState(false);
//     const [deleteModalOpen, setDeleteModalOpen] = useState(false);
//     const [selectedCategory, setSelectedCategory] = useState(null); // State for selected category
//     const [brandImagesModalOpen, setBrandImagesModalOpen] = useState(false);

//     useEffect(() => {
//         fetchProductData();
//         fetchCategoryData(); // Fetch category data
//     }, []);

//     const fetchProductData = async () => {
//         try {
//             const response = await fetch(getAllProducts); // Update with the correct API endpoint for products
//             if (!response.ok) {
//                 throw new Error('Failed to fetch product data');
//             }

//             const data = await response.json();
//             // console.log("data", data);
//             setProductData(data);
//         } catch (error) {
//             console.error('Error fetching product data:', error);
//         }
//     };

//     const fetchCategoryData = async () => {
//         try {
//             const response = await fetch(getAllByCategory); // Update with the correct API endpoint for categories

//             if (!response.ok) {
//                 throw new Error('Failed to fetch category data');
//             }

//             const data = await response.json();
//             setCategoryData(data);
//         } catch (error) {
//             console.error('Error fetching category data:', error);
//         }
//     };

//     const openEditModal = (product) => {
//         setSelectedProduct(product);
//         setEditModalOpen(true);
//     };

//     const closeEditModal = () => {
//         setSelectedProduct(null);
//         setEditModalOpen(false);
//         fetchProductData();
//     };

//     const openDeleteModal = (product) => {
//         setSelectedProduct(product);
//         setDeleteModalOpen(true);
//     };

//     const closeDeleteModal = () => {
//         setSelectedProduct(null);
//         setDeleteModalOpen(false);
//         fetchProductData();
//     };

//     const openBrandImagesModal = (product) => {
//         setSelectedProduct(product);
//         setBrandImagesModalOpen(true);
//     };

//     const closeBrandImagesModal = () => {
//         setSelectedProduct(null);
//         setBrandImagesModalOpen(false);
//         fetchProductData();
//     };
//     // console.log("productDAta", productData);
//     const filteredProducts = selectedCategory
//         ? productData.filter(product => product.category?._id === selectedCategory)
//         : productData;

//     // console.log("productData", productData);

//     return (
//         <div>
//             <h1 className="text-2xl font-bold mb-4 text-center">ShowProducts</h1>
//             <div className='flex justify-between mx-12 mb-8'>
//                 <Select
//                     placeholder="Select a category"
//                     data={categoryData.map(category => ({ value: category._id, label: category.name }))}
//                     value={selectedCategory}
//                     onChange={setSelectedCategory}
//                     clearable
//                 />
//                 <CreateProduct fetchProductData={fetchProductData} />
//             </div>
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-4">
//                 {filteredProducts.map((product) => (
//                     <div key={product._id} className="bg-white rounded-lg overflow-hidden shadow-xl">
//                         {/* {console.log("----product-----", product)} */}

//                         <img src={product?.images[0] || product?.productImage} alt={product.name} className="w-full h-48 object-scale-down" />

//                         <div className="p-4">
//                             <div className="flex flex-row justify-end text-2xl space-x-2 cursor-pointer">
//                                 <div onClick={() => openEditModal(product)}>
//                                     <FaRegEdit />
//                                 </div>
//                                 <div onClick={() => openBrandImagesModal(product)}>
//                                     <CiImageOn className="text-green-700 cursor-pointer" />
//                                 </div>
//                                 <div onClick={() => openDeleteModal(product)}>
//                                     <MdDelete className="text-red-700 cursor-pointer" />
//                                 </div>
//                             </div>
//                             <h2 className="text-xl font-bold mb-2">Name: {product.name}</h2>
//                             <Group justify="">
//                                 <HoverCard width={280} shadow="md">
//                                     <HoverCard.Target>
//                                         <Button variant="filled" size="xs"><Text size="sm" fw={700}> Hover To read description</Text></Button>
//                                     </HoverCard.Target>
//                                     <HoverCard.Dropdown>
//                                         <Text size="sm">
//                                             {product.description}
//                                         </Text>
//                                     </HoverCard.Dropdown>
//                                 </HoverCard>
//                             </Group>
//                             <p className="text-gray-700">Category: {product?.category?.name || "Category might have been deleted"}</p>
//                             <p className="text-gray-700">SubCategory: {product?.subCategory?.name || "SubCategory might have been deleted"}</p>
//                             <p className="text-gray-700">Selling Price: ₹{product.sellingPrice}</p>
//                             <p className="text-gray-700">MRP: ₹{product.mrp}</p>
//                             <p className="text-gray-700">Slug: {product.slug}</p>
//                             {product.specifications && product.specifications.length > 0 && (
//                                 <div>
//                                     <p className="text-gray-700 font-bold">Specifications:</p>
//                                     {product.specifications.map((spec, index) => (
//                                         <div key={index} className="text-gray-700 text-sm">
//                                             <strong>{spec.key}:</strong> {Array.isArray(spec.value) ? spec.value.join(', ') : spec.value}
//                                         </div>
//                                     ))}
//                                 </div>
//                             )}
//                             {product.tags && product.tags.length > 0 && (
//                                 <div className="mt-2">
//                                     <h3 className="text-gray-700 font-bold">Tags:</h3>
//                                     {product.tags.map((tag, index) => (
//                                         <span key={index} className="text-gray-700 text-sm bg-gray-200 rounded-full px-2 py-1 mr-2">
//                                             {tag}
//                                         </span>
//                                     ))}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             {editModalOpen && <EditProduct product={selectedProduct} onClose={closeEditModal} />}
//             {deleteModalOpen && <DeleteProduct product={selectedProduct} onClose={closeDeleteModal} />}
//             {brandImagesModalOpen && <BrnadImages product={selectedProduct} onClose={closeBrandImagesModal} />}
//         </div>
//     );
// }

// export default ShowProducts;


import React, { useState, useEffect } from 'react';
import { Button, Group, HoverCard, Select, Text } from '@mantine/core';
import { getAllProducts, getAllByCategory } from '../../Constant/Api/Api';
import { FaRegEdit, FaQuestionCircle } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { CiImageOn } from "react-icons/ci";
import EditProduct from './EditProduct';
import DeleteProduct from './DeleteProduct';
import CreateProduct from './CreateProduct';
import BrnadImages from './BrandImages/BrandImages';
import CategoryFAQModal from '../category/FAQ/CategoryFAQModal';
import Product from '../Product';
import ProductFAQModal from './FAQ/ProductFAQModal';

function ShowProducts() {
    const [productData, setProductData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [brandImagesModalOpen, setBrandImagesModalOpen] = useState(false);
    const [faqModalOpen, setFaqModalOpen] = useState(false);

    useEffect(() => {
        fetchProductData();
        fetchCategoryData();
    }, []);

    const fetchProductData = async () => {
        try {
            const token = localStorage.getItem('token');
            
            const response = await fetch(getAllProducts, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch product data');
            }
    
            const data = await response.json();
            setProductData(data);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };
    
    const fetchCategoryData = async () => {
        try {
            const token = localStorage.getItem('token');
            
            const response = await fetch(getAllByCategory, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch category data');
            }
    
            const data = await response.json();
            setCategoryData(data);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };

    const openEditModal = (product) => {
        setSelectedProduct(product);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedProduct(null);
        setEditModalOpen(false);
        fetchProductData();
    };

    const openDeleteModal = (product) => {
        setSelectedProduct(product);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedProduct(null);
        setDeleteModalOpen(false);
        fetchProductData();
    };

    const openBrandImagesModal = (product) => {
        setSelectedProduct(product);
        setBrandImagesModalOpen(true);
    };

    const closeBrandImagesModal = () => {
        setSelectedProduct(null);
        setBrandImagesModalOpen(false);
        fetchProductData();
    };

    const openProductFaqModal = (product) => {
        setSelectedProduct(product);
        setFaqModalOpen(true);
    };

    const closeProductFaqModal = () => {
        setSelectedProduct(null);
        setFaqModalOpen(false);
    };

    const filteredProducts = selectedCategory
        ? productData.filter(product => product.category?._id === selectedCategory)
        : productData;

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4 text-center">ShowProducts</h1>
            <div className='flex justify-between mx-12 mb-8'>
                <Select
                    placeholder="Select a category"
                    data={categoryData.map(category => ({ value: category._id, label: category.name }))}
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                    clearable
                />
                <CreateProduct fetchProductData={fetchProductData} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-4">
                {filteredProducts.map((product) => (
                    <div key={product._id} className="bg-white rounded-lg overflow-hidden shadow-xl">
                        <img src={product?.images[0] || product?.productImage} alt={product.name} className="w-full h-48 object-scale-down" />

                        <div className="p-4">
                            <div className="flex flex-row justify-end text-2xl space-x-2 cursor-pointer">
                                <div onClick={() => openProductFaqModal(product)}>
                                    <FaQuestionCircle className="text-green-700 cursor-pointer" />
                                </div>
                                <div onClick={() => openBrandImagesModal(product)}>
                                    <CiImageOn className="text-green-700 cursor-pointer" />
                                </div>
                                <div onClick={() => openEditModal(product)}>
                                    <FaRegEdit />
                                </div>
                                <div onClick={() => openDeleteModal(product)}>
                                    <MdDelete className="text-red-700 cursor-pointer" />
                                </div>
                            </div>
                            <h2 className="text-xl font-bold mb-2">Name: {product.name}</h2>
                            <Group justify="">
                                <HoverCard width={280} shadow="md">
                                    <HoverCard.Target>
                                        <Button variant="filled" size="xs"><Text size="sm" fw={700}> Hover To read description</Text></Button>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Text size="sm">
                                            {product.description}
                                        </Text>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </Group>
                            <p className="text-gray-700">Category: {product?.category?.name || "Category might have been deleted"}</p>
                            <p className="text-gray-700">SubCategory: {product?.subCategory?.name || "SubCategory might have been deleted"}</p>
                            <p className="text-gray-700">Selling Price: ₹{product.sellingPrice}</p>
                            <p className="text-gray-700">MRP: ₹{product.mrp}</p>
                            <p className="text-gray-700">Slug: {product.slug}</p>
                            {product.specifications && product.specifications.length > 0 && (
                                <div>
                                    <p className="text-gray-700 font-bold">Specifications:</p>
                                    {product.specifications.map((spec, index) => (
                                        <div key={index} className="text-gray-700 text-sm">
                                            <strong>{spec.key}:</strong> {Array.isArray(spec.value) ? spec.value.join(', ') : spec.value}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {product.tags && product.tags.length > 0 && (
                                <div className="mt-2">
                                    <h3 className="text-gray-700 font-bold">Tags:</h3>
                                    {product.tags.map((tag, index) => (
                                        <span key={index} className="text-gray-700 text-sm bg-gray-200 rounded-full px-2 py-1 mr-2">
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {editModalOpen && <EditProduct product={selectedProduct} onClose={closeEditModal} />}
            {deleteModalOpen && <DeleteProduct product={selectedProduct} onClose={closeDeleteModal} />}
            {brandImagesModalOpen && <BrnadImages product={selectedProduct} onClose={closeBrandImagesModal} />}
            {faqModalOpen && <ProductFAQModal product={selectedProduct} onClose={closeProductFaqModal} />}
        </div>
    );
}

export default ShowProducts
