import React, { useState, useEffect } from 'react';
import { deleteSubCategoryByID } from '../../Constant/Api/Api'; // Adjust the import as needed
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

function DeleteSubCategory({ subCategory, onClose }) {
    const [opened, { open, close }] = useDisclosure(true);
    const [subCategoryID, setSubCategoryID] = useState('');

    useEffect(() => {
        if (subCategory) {
            setSubCategoryID(subCategory._id || '');
        }
    }, [subCategory]);

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${deleteSubCategoryByID}${subCategoryID}`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log('Subcategory deleted successfully.');
                onClose(); // Close the modal on successful deletion
            } else {
                console.error('Failed to delete subcategory.');
            }
        } catch (error) {
            console.error('Error deleting subcategory:', error);
        }
    };
    

    return (
        <div>
            <Modal opened={opened} onClose={onClose} title="Delete Subcategory">
                <h1 className='bg-slate-100 font-semibold'>Are you sure you want to delete this subcategory?</h1>
                <p>Subcategory ID: {subCategoryID}</p>
                <p>Subcategory Name: {subCategory.name}</p>
                <div className="mt-4 flex justify-end space-x-4">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="red" onClick={handleDelete}>Delete</Button>
                </div>
            </Modal>
        </div>
    );
}

export default DeleteSubCategory;
