import React, { useState, useEffect } from 'react';
import { TextInput, Textarea, Button } from '@mantine/core';
import axios from 'axios';
import { getCategoryByID, addFaq as addFaqApi, updateFaq as updateFaqApi, deleteFaq as deleteFaqApi } from '../../../Constant/Api/Api';
import { debounce } from 'lodash';
const FaqForm = ({ categoryId }) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await axios.get(`${getCategoryByID}/${categoryId}`);
                setFaqs(response.data.faqs);
            } catch (error) {
                console.error('Error fetching category:', error);
            }
        };
        fetchCategory();
    }, [categoryId]);

    const getAuthHeaders = () => {
        const token = localStorage.getItem('token');
        return {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        };
      };
      
    const addFaq = async () => {
        try {
            const response = await axios.post(addFaqApi, {
                id: categoryId,
                question,
                answer
            }, getAuthHeaders());
            setFaqs(response.data.faqs);
            setQuestion('');
            setAnswer('');
        } catch (error) {
            console.error('Error adding FAQ:', error);
        }
    };

    const updateFaq = async (faqId, updatedQuestion, updatedAnswer) => {
        try {
            const response = await axios.post(updateFaqApi, {
                id: categoryId,
                faqId,
                question: updatedQuestion,
                answer: updatedAnswer
            }, getAuthHeaders());
            setFaqs(response.data.faqs);
        } catch (error) {
            console.error('Error updating FAQ:', error);
        }
    };
    const debouncedUpdateFaq = debounce(updateFaq, 800);



    const deleteFaq = async (faqId) => {
        try {
            const response = await axios.post(deleteFaqApi, {
                id: categoryId,
                faqId
            }, getAuthHeaders());
            setFaqs(response.data.faqs);
        } catch (error) {
            console.error('Error deleting FAQ:', error);
        }
    };

    return (
        <div>
            <div className="mb-4">
                <TextInput
                    label="Question"
                    value={question}
                    onChange={(e) => setQuestion(e.currentTarget.value)}
                />
                <Textarea
                    label="Answer"
                    value={answer}
                    onChange={(e) => setAnswer(e.currentTarget.value)}
                />
                <Button className="mt-2" onClick={addFaq}>Add FAQ</Button>
            </div>
            <div>
                {faqs.map((faq) => (
                    <div key={faq._id} className="mb-4 p-4 border rounded shadow-sm">
                        <TextInput
                            value={faq.question}
                            onChange={(e) => debouncedUpdateFaq(faq._id, e.currentTarget.value, faq.answer)}
                        />
                        <Textarea
                            value={faq.answer}
                            onChange={(e) => debouncedUpdateFaq(faq._id, faq.question, e.currentTarget.value)}
                        />
                        <Button color="red" onClick={() => deleteFaq(faq._id)} className="mt-2">Delete FAQ</Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FaqForm;
