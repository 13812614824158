// import React, { useState, useEffect } from 'react';
// import { updateSubCategoryByID, getAllByCategory } from '../../Constant/Api/Api'; // Adjust the import as needed
// import { useDisclosure } from '@mantine/hooks';
// import { Modal, TextInput, FileInput, Button, Select, TagsInput } from '@mantine/core';
// import uploadFile from '../../firebase/firebaseUploadFunction';

// // Utility function to create slug
// const generateSlug = (name) => {
//     return name
//         .toLowerCase()
//         .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
//         .replace(/(^-|-$)+/g, ''); // Remove leading and trailing hyphens
// };

// function EditSubCategory({ subCategory, onClose }) {
//     const [opened, { open, close }] = useDisclosure(true);
//     const [subCategoryID, setSubCategoryID] = useState('');
//     const [subCategoryName, setSubCategoryName] = useState('');
//     const [subCategoryDescription, setSubCategoryDescription] = useState('');
//     const [subCategoryImage, setSubCategoryImage] = useState(null);
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [categories, setCategories] = useState([]);
//     const [tags, setTags] = useState([]);
//     const [slug, setSlug] = useState('');

//     useEffect(() => {
//         if (subCategory) {
//             setSubCategoryID(subCategory._id || '');
//             setSubCategoryName(subCategory.name || '');
//             setSubCategoryDescription(subCategory.description || '');
//             setSubCategoryImage(subCategory.subCategoryImage || null);
//             setSelectedCategory(subCategory.category || ''); // Adjust this based on your data structure
//             setTags(subCategory.tags || []);
//             setSlug(subCategory.slug || generateSlug(subCategory.name || ''));
//         }
//         fetchCategories();
//     }, [subCategory]);

//     useEffect(() => {
//         setSlug(generateSlug(subCategoryName));
//     }, [subCategoryName]);

//     const fetchCategories = async () => {
//         try {
//             const response = await fetch(getAllByCategory);

//             if (!response.ok) {
//                 throw new Error('Failed to fetch categories');
//             }

//             const data = await response.json();
//             setCategories(data);
//         } catch (error) {
//             console.error('Error fetching categories:', error);
//         }
//     };

//     const handleSubCategoryChange = (event) => {
//         setSubCategoryName(event.target.value);
//     };

//     const handleDescriptionChange = (event) => {
//         setSubCategoryDescription(event.target.value);
//     };

//     const handleImageChange = (e) => {
//         uploadFile(e)
//             .then((fireBaseUrl) => {
//                 setSubCategoryImage(fireBaseUrl);
//             })
//             .catch((error) => {
//                 console.error('Error:', error);
//             });
//     };

//     const handleCategoryChange = (value) => {
//         setSelectedCategory(value);
//     };
//     const handleSlugChange = (event) => {
//         setSlug(event.target.value);
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         console.log("submitted");

//         try {
//             const formData = {
//                 name: subCategoryName,
//                 description: subCategoryDescription,
//                 subCategoryImage: subCategoryImage,
//                 category: selectedCategory, // Pass the selected category to the backend
//                 tags: tags,
//                 slug: slug,
//             };

//             const response = await fetch(`${updateSubCategoryByID}${subCategoryID}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(formData),
//             });

//             const data = await response.json();
//             console.log('Success:', data);

//             if (data) {
//                 onClose();
//                 setSubCategoryName('');
//                 setSubCategoryDescription('');
//                 setSubCategoryImage(null);
//             }
//         } catch (error) {
//             console.error('Error updating subcategory:', error);
//         }
//     };

//     return (
//         <div>
//             <Modal opened={opened} onClose={onClose} title="Edit Subcategory">
//                 <form onSubmit={handleSubmit}>
//                     <img src={subCategoryImage} alt="" className='object-scale-down' />
//                     <TextInput
//                         label="Subcategory Name"
//                         placeholder="Enter subcategory name"
//                         value={subCategoryName}
//                         onChange={handleSubCategoryChange}
//                     />
//                     <TextInput
//                         label="Description"
//                         placeholder="Enter subcategory description"
//                         value={subCategoryDescription}
//                         onChange={handleDescriptionChange}
//                     />
//                     <TextInput
//                         label="Slug"
//                         placeholder="Slug"
//                         value={slug}
//                         onChange={handleSlugChange}
//                     />
//                     <Select
//                         label="Category"
//                         placeholder="Select category"
//                         data={categories.map((category) => ({
//                             value: category._id,
//                             label: category.name,
//                         }))}
//                         value={selectedCategory}
//                         onChange={(value) => handleCategoryChange(value)}
//                     />
//                     <input
//                         label="Subcategory Image"
//                         type="file"
//                         accept='image/*'
//                         placeholder="Select image"
//                         onChange={(e) => {
//                             handleImageChange(e);
//                         }}
//                     />
//                     <TagsInput
//                         label="Press Enter to submit a tag"
//                         placeholder="Enter tag"
//                         value={tags}
//                         onChange={setTags}
//                     />
//                     <Button type="submit">Update</Button>
//                 </form>
//             </Modal>
//         </div>
//     );
// }

// export default EditSubCategory;

import React, { useState, useEffect } from "react";
import {
  updateSubCategoryByID,
  getAllByCategory,
} from "../../Constant/Api/Api";
import { Modal, TextInput, Button, Select, TagsInput } from "@mantine/core";
import uploadFile from "../../firebase/firebaseUploadFunction";
import { useDisclosure } from "@mantine/hooks";

// Utility function to create slug
const generateSlug = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
    .replace(/(^-|-$)+/g, ""); // Remove leading and trailing hyphens
};

function EditSubCategory({ subCategory, onClose }) {
  const [opened, { open, close }] = useDisclosure(true);
  const [subCategoryName, setSubCategoryName] = useState(
    subCategory.name || ""
  );
  const [subCategoryDescription, setSubCategoryDescription] = useState(
    subCategory.description || ""
  );
  const [subCategoryImage, setSubCategoryImage] = useState(
    subCategory.subCategoryImage || null
  );
  const [selectedCategory, setSelectedCategory] = useState(
    subCategory.category?._id || ""
  );
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState(subCategory.tags || []);
  const [slug, setSlug] = useState(subCategory.slug || "");
  const [metaName, setMetaName] = useState(subCategory.metaName || "");
  const [metaDescription, setMetaDescription] = useState(
    subCategory.metaDescription || ""
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (subCategory) {
      setSubCategoryName(subCategory.name || "");
      setSubCategoryDescription(subCategory.description || "");
      setSubCategoryImage(subCategory.subCategoryImage || null);
      setSelectedCategory(subCategory.category?._id || "");
      setTags(subCategory.tags || []);
      setSlug(subCategory.slug || "");
    }
  }, [subCategory]);

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(getAllByCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleSubCategoryChange = (event) => {
    setSubCategoryName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setSubCategoryDescription(event.target.value);
  };

  const handleMetaNameChange = (event) => {
    setMetaName(event.target.value);
  };

  const handleMetaDescriptionChange = (event) => {
    setMetaDescription(event.target.value);
  };

  const handleImageChange = (e) => {
    uploadFile(e)
      .then((fireBaseUrl) => {
        setSubCategoryImage(fireBaseUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const formData = {
        name: subCategoryName,
        metaName: metaName || subCategoryName,
        description: subCategoryDescription,
        metaDescription: metaDescription || subCategoryDescription,
        subCategoryImage: subCategoryImage,
        category: selectedCategory,
        tags: tags,
        slug: slug || generateSlug(subCategoryName), // Fallback to generated slug if slug is empty
      };

      const response = await fetch(
        `${updateSubCategoryByID}${subCategory._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      if (data) {
        onClose();
        setSubCategoryName("");
        setSubCategoryDescription("");
        setSubCategoryImage(null);
        setSelectedCategory("");
        setTags([]);
      }
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };

  return (
    <div>
      <Modal opened={opened} onClose={onClose} title="Edit Subcategory">
        <form onSubmit={handleSubmit}>
          {subCategoryImage && (
            <img
              src={subCategoryImage}
              alt="Subcategory"
              className="object-scale-down"
            />
          )}
          <TextInput
            label="Subcategory Name"
            placeholder="Enter subcategory name"
            value={subCategoryName}
            onChange={handleSubCategoryChange}
            required
          />
          <TextInput
            label="Meta Title (SEO)"
            placeholder="Enter meta title"
            value={metaName}
            onChange={handleMetaNameChange}
          />
          <TextInput
            label="Description"
            placeholder="Enter subcategory description"
            value={subCategoryDescription}
            onChange={handleDescriptionChange}
          />
          <TextInput
            label="Meta Description (SEO)"
            placeholder="Enter meta description"
            value={metaDescription}
            onChange={handleMetaDescriptionChange}
          />
          <TextInput
            label="Slug"
            placeholder="Slug"
            value={slug}
            onChange={handleSlugChange}
          />
          <Select
            label="Category"
            placeholder="Select category"
            data={categories.map((category) => ({
              value: category._id,
              label: category.name,
            }))}
            value={selectedCategory}
            onChange={handleCategoryChange}
          />
          <input
            type="file"
            accept="image/*"
            placeholder="Select image"
            onChange={handleImageChange}
          />
          <TagsInput
            label="Press Enter to submit a tag"
            placeholder="Enter tag"
            value={tags}
            onChange={setTags}
          />
          <Button type="submit">Update</Button>
        </form>
      </Modal>
    </div>
  );
}

export default EditSubCategory;
