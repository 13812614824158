import React, { useState, useEffect } from "react";
import { createCategory } from "../../Constant/Api/Api";
import {
  Checkbox,
  TagsInput,
  Textarea,
  Progress,
  Modal,
  TextInput,
  Button,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import uploadFile from "../../firebase/firebaseUploadFunction";

function CreateCategory({ fetchCategoryData }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [categoryName, setCategoryName] = useState("");
  const [metaName, setMetaName] = useState('');
  const [name2, setName2] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState('');
  const [secondDescription, setSecondDescription] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [slug, setSlug] = useState("");
  const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
  const [showOnHeader, setShowOnHeader] = useState(false);
  const [tags, setTags] = useState([]);

  const categoryNameToSlug = (name) => {
    const slugName = name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    setSlug(slugName);
  };

  const handleCategoryChange = (event) => {
    setCategoryName(event.target.value);
    categoryNameToSlug(event.target.value);
  };

  const handleMetaNameChange = (event) => {
    setMetaName(event.target.value);
};


const handleMetaDescriptionChange = (event) => {
    setMetaDescription(event.target.value);
};

  const handleName2Change = (event) => {
    setName2(event.target.value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setCategoryDescription(event.target.value);
  };

  const handleSecondDescriptionChange = (event) => {
    setSecondDescription(event.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    
    // Check if file exists
    if (!file) return;
    
    // Check file size (100KB = 100 * 1024 bytes)
    const maxSize = 100 * 1024; // 100KB in bytes
    
    if (file.size > maxSize) {
      // Show error message to user
      alert("Image size should not exceed 100KB");
      // Clear the file input
      e.target.value = '';
      return;
    }
    
    // Proceed with upload if size is within limit
    setImageUploadingLoader(true);
    uploadFile(e)
      .then((fireBaseUrl) => {
        setCategoryImage(fireBaseUrl);
        setImageUploadingLoader(false);
      })
      .catch((error) => {
        setImageUploadingLoader(false);
        setCategoryImage(null);
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const token = localStorage.getItem('token');
      
      const formData = {
        name: categoryName,
        metaName: metaName || categoryName,
        name2: name2,
        slug: slug,
        description: categoryDescription,
        metaDescription: metaDescription || categoryDescription,
        secondDescription: secondDescription,
        categoryImage: categoryImage,
        showOnHeader: showOnHeader,
        tags: tags,
      };
      
      console.log("Sending data to backend:", formData);
  
      const response = await fetch(createCategory, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      if (data) {
        close();
        // Reset form fields
        setCategoryName("");
        setName2("");
        setCategoryDescription("");
        setSecondDescription("");
        setMetaName("");
        setMetaDescription("");
        setCategoryImage(null);
        setSlug("");
        setShowOnHeader(false);
        setTags([]);
        fetchCategoryData();
      }
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  return (
    <div>
      <Button onClick={open}>Create Category</Button>
      <Modal
        opened={opened}
        onClose={close}
        size="auto"
        title="Create Category"
      >
        {/* {console.log("metaCategoryName:", metaCategoryName)} */}
        <form onSubmit={handleSubmit} className="space-y-2">
          {imageUploadingLoader ? (
            <>
              <span className="bg-red-50">
                please wait while image is uploading...{" "}
              </span>
              <Progress radius="xl" value={100} striped animated />
            </>
          ) : (
            categoryImage && (
              <img
                src={categoryImage}
                alt="Category"
                className="w-full h-36 object-cover"
              />
            )
          )}
          <TextInput
            label="Category Name"
            placeholder="Enter category name"
            value={categoryName}
            onChange={handleCategoryChange}
            required
          />

          <TextInput
            label="Meta Title (SEO)"
            placeholder="Enter meta title"
            value={metaName}
            onChange={handleMetaNameChange}
          />
          <TextInput
            label="Second Title"
            placeholder="Enter second name"
            value={name2}
            onChange={handleName2Change}
            required
          />
          <TextInput
            label="Slug"
            placeholder="Enter category Slug (eg: road-safety-equipment)"
            value={slug}
            onChange={handleSlugChange}
            required
          />
          <Textarea
            label="Description"
            placeholder="Enter category description"
            value={categoryDescription}
            onChange={handleDescriptionChange}
            required
          />
          <Textarea
            label="Meta Description (SEO)"
            placeholder="Enter meta description"
            value={metaDescription}
            onChange={handleMetaDescriptionChange}
          />
          <Textarea
            label="Second Description"
            placeholder="Enter second description"
            value={secondDescription}
            onChange={handleSecondDescriptionChange}
            required
          />
          <input
            type="file"
            accept="image/*"
            placeholder="Select image"
            onChange={handleImageChange}
            required
          />
          <TagsInput
            label="Press Enter to submit a tag"
            placeholder="Enter tag"
            value={tags}
            onChange={setTags}
          />
          <Checkbox
            label="Show on header"
            checked={showOnHeader}
            onChange={(e) => setShowOnHeader(e.currentTarget.checked)}
          />

          <Button type="submit">Create</Button>
        </form>
      </Modal>
    </div>
  );
}

export default CreateCategory;
